import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Lenis from 'lenis';

import './App.css';

const HomePage = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const About = lazy(() => import('./pages/About'));
const Production = lazy(() => import('./pages/Production'));
const ServicesPage = lazy(() => import('./pages/ServicesPage'));
const WebDevelopment = lazy(() => import('./pages/WebDevelopment'));
const Blogs = lazy(() => import('./pages/Blogs'));
const NotFound = lazy(() => import('./pages/NotFound'));

const App = () => {
  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="h-screen flex justify-center items-center">
              <p className="shimmer text-6xl max-sm:text-2xl">
                Perseus Creative Studio ...
              </p>
            </div>
          }
        >
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/visual-production" element={<Production />} />
            <Route path="/web-development" element={<WebDevelopment />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
