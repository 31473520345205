import realEstateIphone from '/assets/videos/realEstateIphone.mp4';

import kengo1V from '/assets/videos/kengo1-video.mp4';
import showrooomV from '/assets/videos/showroom.mp4';
import studyV from '/assets/videos/study-video.mp4';
import genesisV from '/assets/videos/genesis-walkaround.mp4';
import perseusSmallHero from '/assets/videos/perseus-smallHero.mp4';
import perseusLM from '/assets/videos/perseus-logo-motion.mp4';

import perseus from '/assets/images/icons/perseus.svg';
import perseusWhite from '/assets/images/perseus-white.png';
import watch from '/assets/images/icons/watch.svg';
import right from '/assets/images/icons/right.svg';
import replay from '/assets/images/icons/replay.svg';
import play from '/assets/images/icons/play.svg';
import pause from '/assets/images/icons/pause.svg';

import study1 from '/assets/images/study1.jpg';
import taurus1 from '/assets/images/taurus1.jpeg';
import taurus2 from '/assets/images/taurus2.jpeg';
import genesis1 from '/assets/images/genesis1.jpg';
import kengo1 from '/assets/images/kengo1.jpg';
import kengo2 from '/assets/images/kengo2.jpg';
import kengo3 from '/assets/images/kengo3.jpg';
import kengo4 from '/assets/images/kengo4.jpg';

import CFR1 from '/assets/images/CFR1.jpg';
import CFR2 from '/assets/images/CFR2.jpg';
import CFR3 from '/assets/images/CFR3.jpg';
import frame from '/assets/images/frame.png';
import videography from '/assets/images/icons/videography.svg';
import socialMediaManagement from '/assets/images/icons/socialmediamanagement.svg';
import branding from '/assets/images/icons/branding.svg';
import photo from '/assets/images/icons/photo.svg';
import webDesign from '/assets/images/icons/webdesign.svg';
import ads from '/assets/images/icons/advertisements.svg';
import contentCreation from '/assets/images/icons/contentcreation.svg';

// partnerships
import naniFc from '/assets/images/partnerships/nanifc.jpg';
import fitbodega from '/assets/images/partnerships/fitbodega.png';
import tst from '/assets/images/partnerships/tst.jpg';
import vmc from '/assets/images/partnerships/vmc.png';
import genesisLogo from '/assets/images/partnerships/genesis.png';
import cartocci from '/assets/images/partnerships/cartocci.png';
import study from '/assets/images/partnerships/Study.png';
import taurus from '/assets/images/partnerships/Taurus.png';
import visa from '/assets/images/partnerships/Visa.png';
import streetBall from '/assets/images/partnerships/streetball.jpg';
import arshiaEsmaeili from '/assets/images/partnerships/arshiaEsmaeili.jpg';
import artbuild from '/assets/images/partnerships/artbuild.jpg';
import genesisBoxing from '/assets/images/partnerships/genesisBoxingClub.jpg';
import samamiralaei from '/assets/images/partnerships/samamiralaei.png';
import cfr from '/assets/images/partnerships/cfr-logo.jpg';
import gsc from '/assets/images/partnerships/gsc.jpg';
import cindys from '/assets/images/partnerships/cindys.jpg';
import wvtc from '/assets/images/partnerships/wvtc.png';
import kasraz from '/assets/images/partnerships/kasrazLogo.png';
import katwel from '/assets/images/partnerships/katwelLogo.png';
import sturdybee from '/assets/images/partnerships/sturdybeeLogo.png';
import velaHomes from '/assets/images/partnerships/velaHomes.png';
import BCC from '/assets/images/partnerships/BCCLogo.jpg';

// second gallery
import secondGallery1 from '/assets/images/secondGallery/secondGallery1.jpg';
import secondGallery2 from '/assets/images/secondGallery/secondGallery2.jpg';
import secondGallery3 from '/assets/images/secondGallery/secondGallery3.jpg';
import secondGallery4 from '/assets/images/secondGallery/secondGallery4.jpg';
import secondGallery5 from '/assets/images/secondGallery/secondGallery5.jpg';
import secondGallery6 from '/assets/images/secondGallery/secondGallery6.jpg';
import secondGallery7 from '/assets/images/secondGallery/secondGallery7.jpg';
import secondGallery8 from '/assets/images/secondGallery/secondGallery8.jpg';
import secondGallery9 from '/assets/images/secondGallery/arshiaEsmaeiliHeadshot.jpg';

// third gallery
import thirdGallery1 from '/assets/images/thirdGallery/thirdGallery1.jpg';
import thirdGallery2 from '/assets/images/thirdGallery/thirdGallery2.jpg';
import thirdGallery3 from '/assets/images/thirdGallery/thirdGallery3.jpg';
import thirdGallery4 from '/assets/images/thirdGallery/thirdGallery4.jpg';
import thirdGallery5 from '/assets/images/thirdGallery/thirdGallery5.jpg';
import thirdGallery6 from '/assets/images/thirdGallery/thirdGallery6.jpg';

// forth  gallery
import forthGallery1 from '/assets/images/forthGallery/forthGallery1.jpg';
import forthGallery2 from '/assets/images/forthGallery/forthGallery2.jpg';
import forthGallery3 from '/assets/images/forthGallery/forthGallery3.jpg';
import forthGallery4 from '/assets/images/forthGallery/forthGallery4.jpg';
import forthGallery5 from '/assets/images/forthGallery/forthGallery5.jpg';
import forthGallery6 from '/assets/images/forthGallery/forthGallery6.jpg';
import forthGallery7 from '/assets/images/forthGallery/forthGallery7.jpg';
import forthGallery9 from '/assets/images/forthGallery/forthGallery9.jpg';
import forthGallery10 from '/assets/images/forthGallery/forthGallery10.jpg';
import forthGallery11 from '/assets/images/forthGallery/forthGallery11.jpg';
import forthGallery12 from '/assets/images/forthGallery/forthGallery12.jpg';

// services
import kasraz1 from '../../public/assets/images/kasraz-1.jpg';
import kasraz2 from '../../public/assets/images/kasraz-2.jpg';
import taurusInfinite from '../../public/assets/images/taurus-infinite.jpg';
import genesisInfinite from '../../public/assets/images/genesis-infinite.jpg';
import kengoInfinite from '../../public/assets/images/kengo-infinite.jpg';

import solution from '../../public/assets/images/services/solution.png';
import achieve from '../../public/assets/images/services/achieve.png';

export const kasraaz1Img = kasraz1;
export const kasraz2Img = kasraz2;
export const taurusInfiniteImg = taurusInfinite;
export const genesisInfiniteImg = genesisInfinite;
export const kengoInfiniteImg = kengoInfinite;
export const solutionImg = solution;
export const achieveImg = achieve;

export const realEstateIphoneVideo = realEstateIphone;
export const kengo1Video = kengo1V;
export const showroomVideo = showrooomV;
export const studyVideo = studyV;
export const genesisVideo = genesisV;
export const perseusSmallHeroVideo = perseusSmallHero;
export const perseusLogoMotion = perseusLM;

export const perseusImg = perseus;
export const perseusWhiteImg = perseusWhite;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

export const taurus1Img = taurus1;
export const taurus2Img = taurus2;
export const study1Img = study1;
export const genesis1Img = genesis1;
export const kengo1Img = kengo1;
export const kengo2Img = kengo2;
export const kengo3Img = kengo3;
export const kengo4Img = kengo4;
export const CFR1Img = CFR1;
export const CFR2Img = CFR2;
export const CFR3Img = CFR3;
export const videographyImg = videography;
export const socialMediaManagementImg = socialMediaManagement;
export const brandingImg = branding;
export const photoImg = photo;
export const webDesignImg = webDesign;
export const advertisementsImg = ads;
export const contentCreationImg = contentCreation;

export const frameImg = frame;

// partnerships
export const naniFcLogo = naniFc;
export const fitbodegaLogo = fitbodega;
export const tstLogo = tst;
export const vmcLogo = vmc;
export const genesisImg = genesisLogo;
export const cartocciImg = cartocci;
export const studyImg = study;
export const taurusImg = taurus;
export const visaImg = visa;
export const streetBallLogo = streetBall;
export const arshiaEsmaeiliLogo = arshiaEsmaeili;
export const artbuildLogo = artbuild;
export const genesisBoxingLogo = genesisBoxing;
export const samamiralaeiLogo = samamiralaei;
export const cfrLogo = cfr;
export const gscLogo = gsc;
export const cindysLogo = cindys;
export const wvtcLogo = wvtc;
export const kasrazLogo = kasraz;

export const katwelLogo = katwel;
export const sturdybeeLogo = sturdybee;
export const velaHomesLogo = velaHomes;
export const BCCLogo = BCC;

// 2nd & 3rd & 4th gallery
export const secondGallery1Img = secondGallery1;
export const secondGallery2Img = secondGallery2;
export const secondGallery3Img = secondGallery3;
export const secondGallery4Img = secondGallery4;
export const secondGallery5Img = secondGallery5;
export const secondGallery6Img = secondGallery6;
export const secondGallery7Img = secondGallery7;
export const secondGallery8Img = secondGallery8;
export const secondGallery9Img = secondGallery9;

export const thirdGallery1Img = thirdGallery1;
export const thirdGallery2Img = thirdGallery2;
export const thirdGallery3Img = thirdGallery3;
export const thirdGallery4Img = thirdGallery4;
export const thirdGallery5Img = thirdGallery5;
export const thirdGallery6Img = thirdGallery6;

export const forthGallery1Img = forthGallery1;
export const forthGallery2Img = forthGallery2;
export const forthGallery3Img = forthGallery3;
export const forthGallery4Img = forthGallery4;
export const forthGallery5Img = forthGallery5;
export const forthGallery6Img = forthGallery6;
export const forthGallery7Img = forthGallery7;
export const forthGallery9Img = forthGallery9;
export const forthGallery10Img = forthGallery10;
export const forthGallery11Img = forthGallery11;
export const forthGallery12Img = forthGallery12;
